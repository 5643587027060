// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCXe3kdKBxviGf5sMTs9tAUdPa0sI10jdQ",
    authDomain: "bubls-app.firebaseapp.com",
    projectId: "bubls-app",
    storageBucket: "bubls-app.appspot.com",
    messagingSenderId: "747065057670",
    appId: "1:747065057670:web:1bfa635ae2ff5389f14c07",
    measurementId: "G-6ELBKEPBQ7"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, auth, googleProvider, storage, firestore};