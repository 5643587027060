import React,{Component} from 'react';

class UpdateEvent extends Component {
    state = {
        isOpen: false,
        updatedEvent: {
            eventTitle: this.props.event.eventTitle || "", // Initialize with existing event details
            date: this.props.event.date || "",
            time: this.props.event.time || "",
            description: this.props.event.description || "",
            location: this.props.event.location || "",
            isPublic: this.props.event.isPublic || false,

        },
    };
    handleInputChange = (field, value) => {
        this.setState(prevState => ({
            updatedEvent: {
                ...prevState.updatedEvent,
                [field]: value,
            }
        }));
    };

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        const menuClass = `${this.state.isOpen ? " show" : ""}`;
        const {onDelete, onUpdate, eventId} = this.props;
        const { eventTitle, date, time, description, location, isPublic } = this.state.updatedEvent;

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <div className="card-body p-0">
                    <h4 className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center">Bubl-up your event with some tweaks.</h4>
                </div>
                <div className="card-body p-0 mt-3 position-relative">
                    {/* Event title */}
                    <input
                        placeholder="Event Title..."
                        value={eventTitle}
                        onChange={(e) => this.handleInputChange('eventTitle', e.target.value)}
                        className="bor-0 w-100 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mt-2"
                        maxLength="42"
                    />

                    <div className="d-flex align-items-center mt-2">
                        {/* Date Input */}
                        <input
                            id="eventDate"
                            type="date"
                            placeholder="Date..."
                            value={date}
                            onChange={(e) => this.handleInputChange('date', e.target.value)}
                            className="bor-0 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mr-2"
                        />

                        {/* Time Input */}
                        <input
                            id="eventTime"
                            type="time"
                            placeholder="Time..."
                            value={time}
                            onChange={(e) => this.handleInputChange('time', e.target.value)}
                            className="bor-0 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                        />
                    </div>

                    {/* Description */}
                    <textarea
                        maxLength="144"
                        placeholder="Description..."
                        value={description}
                        onChange={(e) => this.handleInputChange('description', e.target.value)}
                        className="h100 bor-0 w-100 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mt-2"
                    ></textarea>

                    {/* Location */}
                    <input
                        maxLength="25"
                        placeholder="Location..."
                        value={location}
                        onChange={(e) => this.handleInputChange('location', e.target.value)}
                        className="bor-0 w-100 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mt-2"
                    />

                    <div className="d-flex justify-content-between align-items-center mt-2 event-checkbox-container">
                        <div className="d-flex align-items-center">
                            <input
                                type="checkbox"
                                className="event-checkbox"
                                id="publicEventCheckbox"
                                checked={isPublic}
                                onChange={(e) => this.handleInputChange('isPublic', e.target.checked)}
                            />
                            <label className="event-checkbox-label font-xssss text-grey-500 ml-2" htmlFor="publicEventCheckbox">Public Event</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2 event-button-container">
                            <button onClick={() => onDelete(eventId)} className="btn custom-delete-btn mr-3">Delete</button>
                            <button onClick={() => onUpdate(eventId, this.state.updatedEvent)} className="btn custom-update-btn">Update</button>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

export default UpdateEvent;

/*
<div className="d-flex align-items-center">
                            <a href="#photo" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                                <i className="font-md text-success feather-image me-2"></i>
                                <span className="d-none-xs">Photo/Video</span>
                            </a>
                        </div>
 */