import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import './main.scss';


import Demo from './demo/Demo';

import Group from './pages/Group';
import Settings from './pages/Settings';
import Account from './pages/Account';
import Contactinfo from './pages/Contactinfo';
import Socialaccount from './pages/Socialaccount';
import Password from './pages/Password';
import Helpbox from './pages/Helpbox';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Notfound from './pages/Notfound';

import Comingsoon from './pages/Comingsoon';


import Grouppage from './pages/Grouppage';
import Userpage from './pages/Userpage';

import MyEvents from './pages/MyEvents';
import Events from './pages/Events';


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
  render(){
      return(
          <BrowserRouter basename={'/'}>
              <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/>


                    <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Events}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/myevents`} component={MyEvents}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/userpage`} component={Userpage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/accountinformation`} component={Account}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/defaultsettings`} component={Settings}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/password`} component={Password}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/forgot`} component={Forgot}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contactinformation`} component={Contactinfo}/>


                    <Route exact path={`${process.env.PUBLIC_URL}/grouppage`} component={Grouppage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/notfound`} component={Notfound}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>



                    <Route exact path={`${process.env.PUBLIC_URL}/defaultnoti`} component={Comingsoon}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/socialaccount`} component={Comingsoon}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/helpbox`} component={Comingsoon}/>


                    {/* Catch-All Route */}
                    <Route component={Notfound}/>
                    
              </Switch>
          </BrowserRouter>
      )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();