import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import EventItem from "../components/EventItem";
import UpdateEvent from '../components/UpdateEvent';
import Postview from '../components/Postview';
import Leftnav from "../components/Leftnav";
import { app, auth, googleProvider, storage, firestore } from "../config/firebase";
import {ref, uploadBytes} from "firebase/storage";
import {
    getDocs,
    collection,
    addDoc,
    deleteDoc,
    updateDoc,
    doc,
} from "firebase/firestore";
import Load from "../components/Load";




class MyEvents extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.state = {
            eventsList: [],
            eventImages: {}, // Add a state property for event images
            eventTitle: '',
            date: '',
            time: '',
            description: '',
            location: '',
            organizedBy: '',
            isPublic: true,
            fileUpload: null
        };
    }

    eventsCollectionRef = collection(firestore, "events");

    fetchImageFromPexels = async (query) => {
        try {
            const response = await fetch(`https://api.pexels.com/v1/search?query=${query}&per_page=1`, {
                headers: {
                    Authorization: 'D4d8eDC1Rl1tm469nrGtQOQG3fU1KengLQyI5GZedjh2vBmp10e4RJOS'
                }
            });
            const data = await response.json();
            return data.photos[0].src.original;
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    }

    async componentDidMount() {
        this.getEventsList();

        // Fetch images for the events after retrieving the events list
        this.getEventImages();
    }

    getEventsList = async () => {
        try {
            const data = await getDocs(this.eventsCollectionRef);
            const filteredData = data.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            this.setState({ eventsList: filteredData }, this.getEventImages);  // After setting eventsList, fetch event images
        } catch (err) {
            console.error(err);
        }
    };


    getEventImages = async () => {
        const { eventsList } = this.state;

        const eventImages = {};

        for (let event of eventsList) {
            const imageUrl = await this.fetchImageFromPexels(event.eventTitle || event.eventName || event.description);
            if (imageUrl) {
                eventImages[event.id] = imageUrl;
            }
        }

        // Set all images to state at once to prevent unnecessary rerenders
        this.setState({ eventImages });
    };

    onSubmit = async () => {
        const { eventTitle, date, time, description, location, organizedBy, isPublic} = this.state;
        try {
            await addDoc(this.eventsCollectionRef, {
                eventTitle,
                date,
                time,
                description,
                location,
                isPublic,
                organizedBy: auth?.currentUser?.uid,
            });
            this.getEventsList();
        } catch (err) {
            console.error(err);
        }
    };

    deleteEvent = async (id) => {
        const eventDoc = doc(firestore, "events", id);
        await deleteDoc(eventDoc);
        this.getEventsList(); // Refresh the list after deletion
    };

    updateEvent = async (id, updatedEvent) => {
        const eventDoc = doc(firestore, "events", id);
        await updateDoc(eventDoc, updatedEvent);
        this.getEventsList(); // Refresh the list after updating
    }

    uploadFile = async () => {
        const {fileUpload} = this.state;
        if (!fileUpload) return;
        const filesFolderRef = ref(storage, `projectFiles/${fileUpload.name}`);
        try {
            await uploadBytes(filesFolderRef, fileUpload);
            console.log("File uploaded successfully!");
        } catch (err) {
            console.error(err);
        }
    };
    handleInputChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    render() {
        const { eventsList, eventTitle, date, time, description, location, isPublic } = this.state;
        return (
            <Fragment>
                <Header/>
                <Leftnav />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">

                                            {eventsList.map((event, index) => (
                                                <div key={index}> {/* A container div with a unique key */}
                                                    <UpdateEvent
                                                        eventTitle={eventTitle}
                                                        date={date}
                                                        time={time}
                                                        description={description}
                                                        location={location}
                                                        isPublic={isPublic}
                                                        eventId={event.id}
                                                        event={event}
                                                        eventImage={this.state.eventImages[event.id]}
                                                        onUpdate={this.updateEvent}
                                                        onDelete={this.deleteEvent}
                                                        handleInputChange={this.handleInputChange}
                                                    />
                                                </div>
                                            ))}
                                    <Load />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </Fragment>
        );
    }
}

export default MyEvents;