import React,{Component} from 'react';
import './demo.css';



class Demo extends Component {
    render() {
        return (
            <div>

            <div className="header-wrapper demo-style">
                <div className="container max-container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6"><a href="/" className="logo"><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">bubls.</span> </a></div>
                    </div>
                </div>
            </div>

            <div className="banner-wrapper vh-100 bscover demo-style"
            style={{background: 'linear-gradient(135deg, #85D8FF, #9E8EFC, #80F2B6, #FFDF85)'}}>
                <div className="banner-content">
                    <div className="container max-container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-8">

                                <h2 className="title-text mb-5 mt-5">
                                    <b>Explore your social bubble with <span className="d-inline-block fredoka-font ls-3 fw-600 text-current logo-text mb-0">bubls<span className="bubls-bubble"></span></span></b>
                                </h2>

                                <div className="clearfix"></div>
                                <a href="/login" className="btn btn-lg btn-primary mr-4 text-uppercase mt-5 me-2">Login</a>

                                <a href="/register" className="btn btn-lg btn-secondary text-uppercase mt-5">Register</a>
                                <div className="icon-scroll pos-bottom-center icon-white"></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>



                <div className="p100 bg-black demo-style" id="contact">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 className="title-text2 text-white mt-4">
                                    A Message From Our CEO:
                                </h2>
                                <p className="text-white mx-lg-8 px-lg-4 px-3 py-2" style={{fontSize: '1.2em', lineHeight: '1.6'}}>
                                    <strong>Dear Bubls Community,</strong>
                                    <br/><br/>
                                    It brings me immense joy to welcome you to Bubls, an innovation born out of a passion to redefine our social narratives. In this fast-paced world, we often lose touch with moments that matter and connections that count. Bubls was envisioned as a bridge to that missing link. Here, memories are relived, friendships are nurtured, and events are celebrated with unparalleled fervor. As you embark on this exciting journey with us, remember that each bubble you form, each event you attend, and every connection you make, echoes our commitment to bringing the world closer.
                                    <br/><br/>
                                    Here's to creating, sharing, and cherishing!
                                    <br/>
                                    <strong>Warm Regards,</strong>
                                    <br/>
                                    <strong>Sam</strong>
                                    <br/>
                                    Founder & CEO, Bubls
                                </p>


                                <div className="col-sm-12 text-center mt-5"><a href="/register" className="btn-lg btn bg-white">Register NOW & BE AMAZED!</a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Demo;