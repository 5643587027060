import React, { Component , Fragment } from "react";
import { auth, googleProvider } from '../config/firebase'; // Adjust the import path as needed
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { withRouter } from 'react-router-dom';


class Login extends Component {
    state = {
        email: '',
        password: '',
        errorMessage: '',
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;

        try {
            await signInWithEmailAndPassword(auth, email, password);
            // Redirect to home page
            this.props.history.push(`${process.env.PUBLIC_URL}/home`);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            this.props.history.push(`${process.env.PUBLIC_URL}/home`);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    render() {
        const { email, password, errorMessage } = this.state;

        return (
            <Fragment> 
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6"><a href="/" className="logo"><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">bubls.</span> </a></div>
                            <button className="nav-menu me-0 ms-auto"></button>
            
                            <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                            <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{background: 'linear-gradient(135deg, #85D8FF, #9E8EFC, #80F2B6, #FFDF85)'}}></div>
                            <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700 display1-size display2-md-size mb-3">Login into <br />your account</h2>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={this.handleChange}
                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Your Email Address"
                                                />
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleChange}
                                                    className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                    placeholder="Password"
                                                />
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            </div>
                                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                                            <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">
                                                Login
                                            </button>
                                        </form>
                                        
                                        <div className="col-sm-12 p-0 text-left">
                                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Dont have account <a href="/register" className="fw-700 ms-1">Register</a></h6>
                                        </div>
                                        <div className="col-sm-12 p-0 text-center mt-2">
                                            
                                            <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in with your social account </h6>
                                                <div className="form-group mb-1">
                                                    <button onClick={this.handleGoogleSignIn} className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2">
                                                        <img src="assets/images/icon-1.png" alt="icon" className="ms-2 w40 mb-1 me-5" />
                                                        Sign in with Google
                                                    </button>
                                                </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Login);