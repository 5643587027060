import React, { Component , Fragment } from "react";
import { auth, firestore } from '../config/firebase'; // Adjust the import path as needed
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { withRouter } from 'react-router-dom';


class Register extends Component {
    state = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessage: '',
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password, confirmPassword } = this.state;

        if (password !== confirmPassword) {
            this.setState({ errorMessage: "Passwords do not match" });
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Send email verification
            await sendEmailVerification(user);

            // You can redirect them to a 'verify email' page or home page
            // and handle the verification status there
            this.props.history.push(`${process.env.PUBLIC_URL}/home`);
        } catch (error) {
            this.setState({ errorMessage: error.message });
        }
    }

    render() {
        const { name, email, password, confirmPassword, errorMessage } = this.state;

        return (
            <Fragment> 
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6"><a href="/" className="logo"><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">bubls.</span> </a></div>   <button className="nav-menu me-0 ms-auto"></button>
                            <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                            <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{background: 'linear-gradient(135deg, #85D8FF, #9E8EFC, #80F2B6, #FFDF85)'}}></div>
                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br />your account</h2>                        
                                    <form onSubmit={this.handleSubmit}>
                                        
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <input
                                                 name="name"
                                                 value={name}
                                                 onChange={this.handleChange}
                                                 type="text"  className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Name" />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email Address" />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <input
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                                className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Password" />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        </div>
                                        <div className="form-group icon-input mb-1">
                                            <input
                                                type="Password"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={this.handleChange}
                                                className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirm Password" />
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" className="form-check-input mt-2" id="exampleCheck2" />
                                            <label className="form-check-label font-xsss text-grey-500">Accept Term and Conditions</label>
                                            
                                        </div>
                                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                                        <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">
                                            Register
                                        </button>
                                    </form>

                                    <div className="col-sm-12 p-0 text-left">

                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have account <a href="/login" className="fw-700 ms-1">Login</a></h6>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Register);