import React,{Component} from 'react';


class EventItem extends Component {


    render() {
        const { event, eventImage} = this.props;
        const getMonthName = (dateString) => {
            const date = new Date(dateString);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return monthNames[date.getMonth()];
        }

        const getDay = (dateString) => {
            const date = new Date(dateString);
            return date.getDate();
        }

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"><div className="card-body d-block p-0 mb-3">
                <div className="row ps-2 pe-2">
                    <div className="col-sm-12 p-1">
                        <img src={eventImage} className="image-max-height rounded-3 w-100" alt="event" />
                    </div>
                </div>
            </div>


                <div className="card-body d-flex ps-0 pe-0 pb-0">
                    <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        <h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0">
                            <span className="ls-3 d-block font-xsss text-grey-500 fw-500">{getMonthName(event.date)}</span>
                            {getDay(event.date)}
                        </h4>
                        <span className="d-block font-xss text-grey-500 mt-1">{event.time}</span>
                    </div>
                    <div>
                        <h2 className="fw-700 lh-3 font-xss">{event.eventTitle}</h2>
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
            <i className="ti-location-pin me-1"></i>{event.location}
        </span>
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
            Organized by {event.organizedBy}
        </span>
                        <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
            Public: {event.isPublic ? 'Public' : 'Not Public'}
        </span>
                    </div>
                </div>
                <div className="card-body p-0 me-lg-5">
                    <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                        {event.description}
                    </p>
                </div>

                <div className="card-body p-0 d-flex align-items-center justify-content-between">
                    <ul className="memberlist mt-4 mb-2 ms-0">
                        <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                        <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                        <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                        <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                        <li className="last-member"><a href="/defaultevent" className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+2</a></li>
                    </ul>
                    <button className="btn custom-submit-btn ml-3">Join</button>
                </div>



            </div>

        );
    }
}


export default EventItem;